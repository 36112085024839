<template lang="pug">
om-modal(@beforeOpen="beforeOpen" :name="name" :class="name" :width="420" color="light")
  template(slot="modal-header")
    .heading
      .font-weight-bold.font-size-1--125 {{ $t(`featureForFeedback.${feature}.heading`) }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide(name)" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(slot="modal-body")
    .content
      .font-size-0--875 {{ $t(`featureForFeedback.${feature}.content`) }}
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button.mr-3(ghost @click="$modal.hide(name)") {{ $t('cancel') }}
      om-button(primary @click="sendMessage()" :loading="isProcessing") {{ $t(`featureForFeedback.${feature}.sendMessage`) }}
</template>
<script>
  import { track } from '@/services/xray';
  import REQUEST_TO_ACCESS_FEATURE from '@/graphql/RequestToAccessFeature.gql';
  import { mapState } from 'vuex';

  const featureTypes = {
    POWERED_BY: 'poweredBy',
    DEV_MODE: 'devMode',
  };

  export default {
    data() {
      return {
        name: 'feature-for-feedback',
        feature: featureTypes.POWERED_BY,
        isProcessing: false,
        featureTypes,
      };
    },

    computed: {
      ...mapState(['account']),
    },

    methods: {
      async sendMessage() {
        await this.requestToFeature();
        track(`click-to-send-message-${this.feature}-request`);
        window.Intercom('showMessages');
      },

      async requestToFeature() {
        this.isProcessing = true;
        const { email, locale } = this.account.login;

        try {
          const {
            data: { requestToAccessFeature },
          } = await this.$apollo.query({
            query: REQUEST_TO_ACCESS_FEATURE,
            variables: {
              email,
              locale,
              feature: this.feature,
            },
          });

          if (!requestToAccessFeature) {
            throw new Error();
          }
        } catch (error) {
          this.$notify({
            type: 'error',
            text: this.$t(`featureForFeedback.${this.feature}.error`),
            duration: 5000,
          });
        }

        this.isProcessing = false;
        this.$modal.hide(this.name);
      },

      beforeOpen(event) {
        this.feature = event.params.feature;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .feature-for-feedback
    ::v-deep.brand-modal-header
      padding: 20px 40px
      border-bottom: 1px solid #E3E5E8
    ::v-deep.brand-modal-body
      padding: 20px 40px
    ::v-deep.brand-modal-footer
      padding: 20px 40px 20px 40px
</style>
